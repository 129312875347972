import React, { useEffect } from 'react';

import Layout from '../components/layout';
import SEO from "../components/seo"

const CustomBikes = () => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = 'https://www.autoscout24.ch/MVC/Content/as24-hci-desktop/js/e.min.js';
		script.async = true;

		document.body.appendChild(script);
	}, []);

	return (
		<Layout>
			<SEO
				title="Occasions"
				description="Retrouvez tous nos véhicules d'occasions disponibles dans notre garage."
				lang="fr"
				url="/vehicules-occasions"
			/>

			<div className="box-container first">
				<div className="content-block">
					<div className="content-block-bg" />
					<h2>Véhicules d'occasions</h2>

					<p>Black Way s'efforce de vous proposer constamment des occasions de qualité.</p>

					<div data-embedded-src="https://www.motoscout24.ch/fr/hci/list?design=3381&filter=7207" className="embedded-content-area"></div>
				</div>
			</div>
		</Layout>
	);
}

export default CustomBikes;
